<template>
    <NoQoutes
        v-if="do_show_no_qoutes_error"
        :coverage_items="coverage_items"
        :plan_items="plan_items"
        :assign-values-method="assignValues"
        :loading="loading"
        @meetAgentClick="FindAdvisor"
    />
    <div v-else>
        <v-container class="hidden-sm-and-down">
            <div class="large-device-container pa-md-10">
                <div class="text-center mt-n15" style="color: #005851; font-weight: 800; font-size: 1.2rem;">
                    Your Coverage
                </div>
                <v-card flat style="background: #F7FCFC; font-family: Montserrat" class="pa-4">
                    <v-row>
                        <v-col>
                            <EditQuoteDialog :coverage_items="coverage_items" :plan_items="plan_items" :assign-values-method="assignValues" ref="edit_quote_dialogue" />
                        </v-col>
                        <v-col class="text-left" style="flex-grow: 2">
                            <v-row style="color:black;">
                                <v-col class="text-center v-divider--vertical pr-0">
                                    <div>
                                        Term Length<br>
                                        <span style="font-weight: bold;font-size:1.5rem">{{selected_term_length}}</span>
                                    </div>
                                </v-col>
                                <v-col class="text-center">
                                    <div>
                                        Coverage <br>
                                        <span style="font-weight: bold;font-size:1.5rem"><sup>$</sup>{{selected_Coverage}}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col></v-col>
                    </v-row>
                </v-card>

                <QuoteCard v-for="(data, idx) in computed_card_data" :key="idx" :card_data="data" ></QuoteCard>

                <v-card style="background: white; font-family: Montserrat; " class="pa-4 mt-6">
                    <v-row>
                        <v-col style="font-size:1.1rem;font-family: Montserrat, Bold; font-weight: bold" class="text-center; justify-center mt-7 pl-5 col-3">
                            Meet a Licensed Agent
                        </v-col>
                        <v-col style="font-size: 0.875rem; font-weight: 300;" class="mt-5 col-5">
                            Discuss your options with a licensed agent who can help you find your best-fit plan
                        </v-col>
                        <v-col class="text-center col-4">
                            <v-btn elevation="2" fab large style="background: #005851;" class="mb-1" @click="FindAdvisor">
                                <img src="../assets/Talk-It-Through-Icon.svg">
                            </v-btn>
                            <br>
                            <span style="font-family: Montserrat, SemiBold; font-weight: 600; color: #46C3B2;cursor: pointer;" class="mt-1" @click="FindAdvisor" id="multipleQuotesFindAdvisor">Find a Licensed Agent <img src="../assets/Group 5813.svg"></span>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
            <v-dialog v-model="eApp_dialog" persistent width="300" style="font-family: montserrat, Sans-serif">
                <v-card color="#005851" dark>
                    <v-card-text style="font-family: montserrat, Sans-serif; font-size: 1rem; line-height: 1rem;" class="pa-3">
                        Starting e-Application
                        <v-progress-linear indeterminate color="white" class="mb-0 mt-4"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
        <div class="hidden-md-and-up">
            <am_card_mobile :am_premium="amam_premium" :am_premium_exist="amam_premium_exist" :jh_premium="jh_premium" :jh_method="gotoEapp" :sureify_method="process_sureify" :tobacco_used="m_tobacco_used" :coverage_amount="coverage_amount" :term_length="m_term_length" :coverage_items="coverage_items" :plan_items="plan_items" :callback="assignValues" :quote_array="computed_card_data" :zoho_id="zoho_id"></am_card_mobile>
        </div>
        <QuoteErrorDialog v-model="error_dialogue"></QuoteErrorDialog>
    </div>
</template>
<script>
import QuoteCard from "./QuoteCard";

window._mfq = window._mfq || [];
(function() {
    var mf = document.createElement("script");
    mf.type = "text/javascript";
    mf.defer = true;
    mf.src = "//cdn.mouseflow.com/projects/bc45607e-c708-40a4-b5de-cfecbe5be187.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
})();


import numeral from "numeral";
import axios from "axios";


import am_card_mobile from "@/components/am_card_mobile";
import EditQuoteDialog from "@/components/EditQuoteDialog";

import quilityMixin from "@/mixins/quilityMixin";
import QuoteErrorDialog from "./QuoteErrorDialog";
import NoQoutes from './NoQoutes.vue'


let moment = require('moment');

export default {
    name: "MultipleQuotations",
    mixins: [quilityMixin],
    data: () => ({
        error_dialogue: false,
        amam_benefit_dialog: false,
        jh_benefit_dialog: false,
        edit_quote_dialog: null,
        eApp_dialog: false,
        amam_dialog_help: false,
        jh_dialog_help: false,
        loading: false,
        amam_premium: 0,
        amam_product: null,
        amam_premium_exist: false,
        jh_premium: 0,
        jh_product: null,
        sbli_premium: 0,
        selected_term_length: 0,
        selected_Coverage: 0,
        m_coverage_amount: 0,
        m_term_length: 0,
        m_tobacco_used: null,
        zoho_id: null,
        plan_items: [
            { text: '10 Years', value: '10 Years' },
            { text: '15 Years', value: '15 Years' },
            { text: '20 Years', value: '20 Years' },
            { text: '25 Years', value: '25 Years' },
            { text: '30 Years', value: '30 Years' },
        ],
        btnDisabled: false,
        dialog: false,
        getQuoteData: null,
        coverage_amount: 0,
        quoteContainerClass: null,
        agencyName: null,
        agentName: null,
        agentPhone: null,
        agentEmail: null,
        agentAddress: null,
        agentCity: null,
        agentState: null,
        agentZip: null,
        agentPayrollID: null,
        agentId: null,
        agentGovtId: null,
        card_data: [],
        state_id: null,
        arcId: null,
        agentInfo: null,
    }),
    components: {
        QuoteErrorDialog,
        QuoteCard,
        am_card_mobile,
        EditQuoteDialog,
        NoQoutes,
    },
    mounted() {

        const parameters = this.$route.params;
        if (typeof parameters.form_data !== 'undefined') {
            this.getPremiums(parameters.quotes);
            this.assignValues(parameters.form_data, parameters.quotes);
            this.zoho_id = parameters.zoho !==null ? parameters.zoho.ZohoID : null;
            this.getQuoteData = parameters;
        }

        if (this.$vuetify.breakpoint.name === 'lg') {
            this.quoteContainerClass = 'large-device-container';
        }

        this.state_id = parameters.form_data.state_id;


        if (typeof parameters.agentInfo !== 'undefined') {
            this.agentInfo = parameters.agentInfo;
            this.agentName = parameters.agentInfo.agentName;
            this.agentPhone = parameters.agentInfo.agentPhone;
            this.agentAddress = parameters.agentInfo.agentAddress;
            this.agentCity = parameters.agentInfo.agentCity;
            this.agentZip = parameters.agentInfo.agentZip;
            this.agentState = parameters.agentInfo.agentState;
            this.agentPayrollID = parameters.agentInfo.agentPayrollID;
            this.agentGovtId = parameters.agentInfo.agentGovtId;
            this.agentEmail = parameters.agentInfo.agentEmail;


            if (parameters.site === 'OC') {
                const agentLicenses = parameters.agentInfo.agentLicenses;
                const agentLicensed = (agentLicenses.includes(parameters.form_data.state_id));

                if (agentLicensed != true) {
                    const agentInfo = this.getDefaultOCAgent(); //pick brandon to go to JH eApp
                    this.agentName = agentInfo.agentName;
                    this.agentPhone = agentInfo.agentPhone;
                    this.agentAddress = agentInfo.agentAddress;
                    this.agentCity = agentInfo.agentCity;
                    this.agentZip = agentInfo.agentZip;
                    this.agentState = agentInfo.agentState;
                    this.agentPayrollID = agentInfo.agentPayrollID;
                    this.agentGovtId = agentInfo.agentGovtId;
                    this.agentEmail = agentInfo.agentEmail;
                }
            }
        }

        this.generateQuoteCards();


        var x = null, // eslint-disable-line
            p, q, m,
            o = "32725",
            l = "ORDER ID",
            i = "TOTAL ORDER AMOUNT",
            c = "",
            k = "",
            g = "",
            j = "",
            u = "",
            shadditional = "";
        try { p = top.document.referer !== "" ? encodeURIComponent(top.document.referrer.substring(0, 512)) : "" } catch (n) { p = document.referrer !== null ? document.referrer.toString().substring(0, 512) : "" }
        try { q = window && window.top && document.location && window.top.location === document.location ? document.location : window && window.top && window.top.location && "" !== window.top.location ? window.top.location : document.location } catch (b) { q = document.location }
        try { m = parent.location.href !== "" ? encodeURIComponent(parent.location.href.toString().substring(0, 512)) : "" } catch (z) { try { m = q !== null ? encodeURIComponent(q.toString().substring(0, 512)) : "" } catch (h) { m = "" } }
        var A, y = document.createElement("script"),
            w = null,
            v = document.getElementsByTagName("script"),
            t = Number(v.length) - 1,
            r = document.getElementsByTagName("script")[t];
        if (typeof A === "undefined") { A = Math.floor(Math.random() * 100000000000000000) } w = "dx.mountain.com/spx?conv=1&shaid=" + o + "&tdr=" + p + "&plh=" + m + "&cb=" + A + "&shoid=" + l + "&shoamt=" + i + "&shocur=" + c + "&shopid=" + k + "&shoq=" + g + "&shoup=" + j + "&shpil=" + u + shadditional;
        y.type = "text/javascript";
        y.src = ("https:" === document.location.protocol ? "https://" : "http://") + w;
        r.parentNode.insertBefore(y, r);
    },
    created() {
        const parameters = this.$route.params;
        const query_param = this.$route.query.q;

        if (typeof parameters.form_data === 'undefined' && typeof query_param === 'undefined') {
            this.$router.push({ name: 'GetAQuote' });
        }

        // console.log(parameters);
        this.selected_term_length = parameters.form_data.term_length;
        this.selected_Coverage = this.formatCurrency(parameters.form_data.coverage_amount);
    },
    methods: {
        FindAdvisor() {
            this.loading = true
            let redirect_url = process.env.VUE_APP_TERM_CONSULT_URL
            let gravity_url

            // if no quotes were returned
            // then redirect to gravity form
            // that will handle updating the lead
            // in zoho
            if(this.do_show_no_qoutes_error)
            {
                gravity_url = new URL(process.env.VUE_APP_MEET_AN_AGENT)
                gravity_url.searchParams.set('lead-id',this.zoho_id)
                gravity_url.searchParams.set('lead-phone',this.$route.params.form_data.phone)
                gravity_url.searchParams.set('full-name',`${this.$route.params.form_data.firstName} ${this.$route.params.form_data.lastName}`)
                gravity_url.searchParams.set('email',this.$route.params.form_data.email)

                console.log(`redirecting to ${gravity_url.href}`)
                redirect_url = gravity_url.href
            }

            this.updateDisposition(this.zoho_id, 'RAQ Consult', redirect_url)
        },
        getPremiums(quotes) {
            this.amam_premium_exist = false;
            // console.log(quotes.length);

            if(quotes){
                quotes.forEach((item) => {
                    if (item.Coverage == null || item.Premium == null) {
                        return;
                    }
                    this.selected_term_length = item.Term;
                    this.selected_Coverage = this.formatCurrency(item.Coverage);

                    if (item.Carrier === 'John Hancock') {
                        this.jh_premium = parseFloat(item.Premium).toFixed(2);
                        this.jh_product = item.Product;
                    } else if (item.Carrier === 'SBLI') {
                        this.arcId = item.arcId;
                        this.sbli_premium = parseFloat(item.Premium).toFixed(2);
                    }
                });
            }
        },

        formatCurrency(a) {
            return numeral(a).format('0,0[.]00');
        },
        setCoverageItems(coverage_limit) {
            let r = []
            for (let i = 0; i < coverage_limit; i++) {
                let amt = (25000 + (i * 25000));
                r.push({
                    'text': this.formatCurrency(amt),
                    'value': amt
                })
            }
            r.reverse();
            return r;
        },

        checkQuote(coverage_amt, term_length, tobacco_used) {
            this.loading = true;
            this.btnDisabled = true;
            let ste_url = process.env.VUE_APP_PROXY + 'get-quote';
            const parameters = this.$route.params;

            let postParam = {
                gender: parameters.form_data.gender,
                productType: 'Term',
                smoker: tobacco_used,
                zip: parameters.form_data.zip,
                dob: parameters.form_data.dob,
                coverage_amount: coverage_amt,
                term_length: term_length,
                firstName: parameters.form_data.firstName,
                lastName: parameters.form_data.lastName,
                email: parameters.form_data.email,
                state_id: parameters.form_data.state_id,
                city: parameters.form_data.city,
                age: parameters.form_data.age,
                entry_point: parameters.form_data.entry_point,
                phone: parameters.form_data.phone,
                zoho_id: this.zoho_id,
                mode: 'edit'
            }

            axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            axios.post(ste_url, postParam)
                .then(res => {
                    this.loading = false;
                    this.edit_quote_dialog = false;
                    this.$refs.edit_quote_dialogue.closeDialog();
                    if (res.data.success === true) {
                        this.loading = false;
                        this.btnDisabled = false;
                        let quoteParams = {};
                        quoteParams['quotes'] = res.data.quotes;
                        quoteParams['form_data'] = postParam;
                        // this.$router.push({ name: 'TermCoverage', params: quoteParams })
                        this.assignValues(postParam, res.data.quotes);
                    }
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err)
                });
        },

        assignValues(form_data, quotes) {
            const coverageAmt = form_data.coverage_amount;
            this.coverage_amount = new Intl.NumberFormat().format(coverageAmt);
            this.term_length = form_data.term_length;

            this.m_coverage_amount = coverageAmt;
            this.m_term_length = form_data.term_length;
            this.m_tobacco_used = form_data.smoker;

            this.getPremiums(quotes);
            this.generateQuoteCards();
        },

        gotoEapp() {
            this.eApp_dialog = true;
            const parameters = this.$route.params;
            const phone = parameters.form_data.phone;
            let phone_clean = phone.replace('(', '');
            phone_clean = phone_clean.replace(')', '');

            const queryParam = {
                firstName: parameters.form_data.firstName,
                lastName: parameters.form_data.lastName,
                dob: moment(parameters.form_data.dob, 'YYYY-MM-DD').format('MM-DD-YYYY'),
                gender: parameters.form_data.gender,
                state: parameters.form_data.state_id,
                phone: phone_clean,
                coverageAmount: numeral(this.m_coverage_amount).format('0,0[.]00'),
                coverageLength: this.m_term_length.substring(0, 2),
                smoker: this.m_tobacco_used === 'Yes' ? 'Smoker' : 'NonSmoker',
                healthClass: 'Excellent',
                estimateMonthly: this.jh_premium,
                email: parameters.form_data.email,
                applicantId: parameters.zoho !==null ? parameters.zoho.ZohoID : null,
                salesSource: 'ain',
                salesSourceVariation: 'quility',
                productType: 'lft',
                agentEmail: this.agentEmail,
                agentId: this.agentGovtId,
                agentLicense: this.agentLicense,
                agentLicenseState: parameters.form_data.state_id,
                agentName: this.agentName,
                agentPhone: this.agentPhone,
                agentAddress: this.agentAddress,
                agentCity: this.agentCity,
                agentState: this.agentState,
                agentZip: this.agentZip,
                agentPayrollID: this.agentPayrollID,
                customSkin: 'quility',
                quility_site: (this.$route.params.site === 'OC') ? 'oc' : 'quility',
                token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJpYXQiOjE1NTQ3NTgzNjN9.N2MzMzBhMDY3YTYzNWQ4ODQ5YWU5MjVhNmU4YTAzMGIwODVjM2RmMDU1MTk2Y2IwYjI0ZWRkNDc2ZTg1OWQzOQ=='
            };

            let query_string = Object.keys(queryParam).map(function(key) {
                return key + '=' + queryParam[key];
            }).join('&');

            const redirect_url = process.env.VUE_APP_JH_EAPP_URL + 'processor/intake?' + query_string
            console.log(redirect_url);
            const lead_info = {};
            lead_info['premium'] = this.jh_premium;
            lead_info['zoho_id'] = this.zoho_id;
            lead_info['product'] = this.jh_product;
            lead_info['disposition'] = 'RAQ Apply Now';
            this.updateLeadProductPremium(parameters.form_data, lead_info, 'JH').then(res => {
                console.log(res);
                window.location.href = redirect_url;
            }).catch(error => {
                console.log(error);
                this.error_dialogue = true;
                this.eApp_dialog = false;
            });
        },

        process_sureify() {
            // making sure we can't redirect to sureify
                return false;
        },

        process_sbli() {
            this.eApp_dialog = true;
            const parameters = this.$route.params;

            const lead_info = {};
            lead_info['premium'] = this.sbli_premium;
            lead_info['zoho_id'] = this.zoho_id;
            lead_info['product'] = 'Quility Level Term';
            lead_info['disposition'] = 'RAQ Apply Now';

            this.updateLeadProductPremium(parameters.form_data, lead_info, 'sbli').then(res => {
                const phone = parameters.form_data.phone;
                let phone_clean = phone.replace('(', '');
                phone_clean = phone_clean.replace(')', '');

                const postParam = {
                    email: parameters.form_data.email,
                    leadReferenceId: parameters.zoho.ZohoID,
                    phone: phone_clean,
                    zip: parameters.form_data.zip,
                    firstname: parameters.form_data.firstName,
                    lastname: parameters.form_data.lastName,
                    dob: moment(parameters.form_data.dob, 'YYYY-MM-DD').format('MM/DD/YYYY'),
                    gender: parameters.form_data.gender,
                    state: parameters.form_data.state_id,
                    coverage: parameters.form_data.coverage_amount,
                    term: parameters.form_data.term_length,
                    smoker: parameters.form_data.smoker,
                    arcId: this.arcId,
                    agentCode: (parameters.site === 'OC') ? parameters.agentInfo.agentCode : process.env.VUE_APP_QLTCORPAGENT
                };

                let ste_url = process.env.VUE_APP_PROXY + 'get-sbli-url';
                axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                axios.post(ste_url, postParam).then(response => {
                    console.log(response);
                    if (response.data.success == false) {
                        lead_info['disposition'] = 'Quote Error';
                        this.updateLeadProductPremium(parameters, lead_info, 'sbli').then(res => {
                            console.log(res);
                        }).catch(error => { console.log(error) });
                        this.eApp_dialog = false;
                        return false;
                    }
                    window.location.href = response.data.data.redirectUrl;
                }).catch(error => {
                    lead_info['disposition'] = 'Quote Error';
                    console.log(error);
                    this.eApp_dialog = false;
                    this.error_dialogue = true;
                });
                console.log(res);
            }).catch(error => {
                console.log(error);
                this.error_dialogue = true;
                this.eApp_dialog = false;
            });
        },

        generateQuoteCards() {
            const JH_state_filter = ['FL', 'NY'];
            const state_filter = ['NY'];
            // const parameters = this.$route.params;
            this.card_data = [];

            if (typeof this.jh_premium != 'undefined' && this.jh_premium > 0 && !JH_state_filter.includes(this.state_id)) {
                const jh_card = {
                    mode: 'jh',
                    premium: this.jh_premium,
                    logo: 'John-Hancock_Carrier-Logos.svg',
                    messages: [
                        'Rewards and discounts for living a healthy life',
                        'Instant decision and coverage'
                    ],
                    mobile_msg: [
                        'Rewards and discounts for living a healthy life',
                        'Instant decision and coverage'
                    ],
                    method: 'gotoEapp'
                }
                this.card_data.push(jh_card);
            }

            if (typeof this.sbli_premium != 'undefined' && this.sbli_premium > 0 && !state_filter.includes(this.state_id)) {
                const sbli_card = {
                    mode: 'sbli',
                    premium: this.sbli_premium,
                    logo: 'QLT-image.png',
                    mobile_logo_exists: true,
                    // sub_logo: 'Backed-By-SBLI.svg',
                    sub_logo_exists: false,
                    mobile_logo: 'QLT-image.png',
                    messages: [
                        'Coverage up to <sup>$</sup>1 Million',
                        '10 to 30-year terms',
                        'No medical exam<sup>1</sup>'
                    ],
                    mobile_msg: [
                        'Coverage up to <sup>$</sup>1 Million',
                        '10 to 30-year terms',
                        'No medical exam<sup>1</sup>'
                    ],
                    method: 'process_sbli'
                }
                this.card_data.push(sbli_card);
            }

            this.card_data.sort((a, b) => (parseInt(a.premium) < parseInt(b.premium)) ? -1 : 1);
        }

    }, //eof methods
    computed: {
        coverage_items: {
            get() {
                return this.setCoverageItems(40);
            },
            set(newCoverageItems) {
                return newCoverageItems
            }
        },
        computed_card_data: function() {

            if(this.agentInfo && this.isPersonalCheck(this.agentInfo.agentCode)) {
                return this.card_data.filter((card) => { return card.mode != 'jh'})
            }

            return this.card_data
        },

        do_show_no_qoutes_error() {
            return this.computed_card_data.length === 0
        }
    },
    watch: {
        m_coverage_amount: function(val) {
            return val;
        }
    }
}
</script>
<style scoped>
.large-device-container {
    font-family: Montserrat;
    /*width: 90%;*/
    /*align-content: center;*/
}
</style>