<template>
    <div>
        <v-card class="pa-4 mt-5 q-card" >
            <v-row>
                <v-col class="col-7 no-gutters pr-1" style="border-right: 1px solid #cccccc; ">
                    <v-row>
                        <v-col>
                            <figure>
                                <img v-bind:src="logo" alt="logo" class=" float-left" :style="logo_style" >
                                <v-icon v-if="amam" tabindex="-1" style="font-size: 1rem;" class="ml-3"
                                        @click.stop="amam_benefit_dialog = true" color="#005851">
                                    mdi-help-circle-outline
                                </v-icon>
                                <v-icon v-if="jh" tabindex="-1" style="font-size: 1rem;" class="ml-3"
                                        @click.stop="jh_benefit_dialog = true" color="#005851">
                                    mdi-help-circle-outline
                                </v-icon>
                                <v-icon v-if="sbli" tabindex="-1" style="font-size: 1rem;" class="ml-3"
                                         color="#005851">
                                    mdi-help-circle-outline
                                </v-icon>
                            </figure>
                            <Am_benefit_dialog v-model="amam_benefit_dialog"></Am_benefit_dialog>
                            <Jh_benefit_dialog v-model="jh_benefit_dialog"></Jh_benefit_dialog>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="py-2" v-for="message in card_data.mobile_msg" :key="message">
                        <v-col class="col-12 no-gutters pa-0">
                            <v-row no-gutters>
                                <v-col class="col-1">
                                    <span class="mdi mdi-check check-mark"></span>
                                </v-col>
                                <v-col class="col-11">
                                    <span class="benefit-item" v-html="message"></span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row v-if="card_data.sub_logo_exists">
                        <img v-bind:src="sub_logo" v-if="this.card_data.sub_logo_exists" class="float-left" alt="logo" :style="sub_logo_style" >
                    </v-row>
                </v-col>

                <v-col class="col-5">
                    <v-row>
                        <v-col class="text-center coverage-title">
                            Coverage as low as
                        </v-col>
                    </v-row>
                    <v-row @click="enter_eapp(card_data.mode)">
                        <v-col class="text-center amount-box" id="amamMobileGetThisPlan">
                            <div class="amount">
                                <div class="premium"><sup>$</sup>{{card_data.premium}}</div>
                                <div class="amount-per-month">Monthly<sup>*</sup></div>
                            </div>
                            <v-btn color="q-primary" depressed class="get-started px-3 my-2">
                                Get Started
                                <img class="ml-2" src="../assets/CTA-Arrow-white.svg">
                            </v-btn>
                            <div class="premium-note">
                                This is an initial quote. Your final premium may vary.
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import Am_benefit_dialog from "./Am_benefit_dialog";
import Jh_benefit_dialog from "./Jh_benefit_dialog";

export default {
    name: "QuoteCardMobile",
    components: {
        Jh_benefit_dialog,
        Am_benefit_dialog
    },
    props: {
        card_data: {
            type: Object
        }
    },
    data: () => ({
        amam_dummy: false,
        amam:false,
        jh:false,
        sbli:false,
        show_am_dummy: false,
        amam_benefit_dialog: false,
        jh_benefit_dialog: false,
        am_messages: [],
        amam_premium: null,
        logo: null,
        logo_style: null,
        sub_logo: null,
        sub_logo_style: null,
    }),
    mounted() {
        this.logo = (typeof this.card_data.mobile_logo_exists!='undefined')?this.getImgUrl(this.card_data.mobile_logo):this.getImgUrl(this.card_data.logo);
        this.sub_logo = (this.card_data.sub_logo_exists) ? this.getImgUrl(this.card_data.sub_logo):'';
        this.jh = (this.card_data.mode === 'jh');
        this.sbli = (this.card_data.mode === 'sbli');

        if(this.card_data.mode === 'jh') {
            this.logo_style = 'width:60%';
        } else if(this.card_data.mode === 'sbli') {
            if(this.card_data?.sub_logo_exists) {
                this.sub_logo_style = 'margin-left:7%;'
            }
        }
    },
    methods:{
        getImgUrl(pic) {
            return require('../assets/'+pic)
        },
        enter_eapp(mode) {
            this.$parent.process_eapps(mode);
        }
    }
}
</script>

<style lang="scss" scoped>
$text--secondary: #9B9B9B;

.coverage-title {
    color: $text--secondary;
    font-size: 0.69rem;
}

.amount-box {
    cursor: pointer;
}

.amount div.premium {
    font-family: Montserrat, ExtraBold;
    font-size: 2.0rem;
    line-height: 2rem;
    font-weight: 800;
    white-space: pre-wrap;
    word-break: keep-all;
}

.amount div.amount-per-month {
    font-weight: 300;
    font-size: 0.69rem;
}

.get-started {
    color: #fff !important;
    font-family: Montserrat, SemiBold;
    font-weight: 600;
    font-size: 0.8rem;

    @media only screen and (max-width: 486px) {
        & {
            font-size: 0.7rem !important;
            img {
                width: 12px;
            }
        }
    }
}

.check-mark {
    color:#46C3B2;font-size: 1rem;font-weight: normal;
}

.benefit-item {
    font-size: 0.82rem;font-weight: 300;font-family: Montserrat, Light;
    color:black;
}

.premium-note {
    color: $text--secondary;
    font-size: 0.5rem;
    text-align: left;
}

@media only screen and (max-width: 768px) {
    .cov_sup {
        font-size:0.6rem;
    }
}
</style>