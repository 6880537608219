<template>
    <v-dialog width="580" v-model="show" >
        <v-card>
            <v-card-title class="dialog-header-text">
                Benefits
                <v-spacer></v-spacer>
                <v-icon large color="#46C3B2" @click.stop="show=false">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-5">
                <div style="font-size: 1rem;font-family: Montserrat, Bold; font-weight: bold; color: black" class="mt-7 mb-1">
                    Healthy living rewards
                </div>
                <v-row class="pa-4">
                    <v-col class="col-2 pr-0">
                        <v-img :src="require('../assets/Quiliity-FE_Affordable Premiums Icon.svg')"
                               width="30"
                        ></v-img>
                    </v-col>
                    <v-col class="col-10 pl-0 ml-md-n2 benefit-item-text" >
                        Receive up to 15% in premium cash back each year for making healthy choices.<sup style="font-size:0.6rem;">2</sup>
                    </v-col>
                </v-row>
                <v-row class="pa-4 mt-n9">
                    <v-col class="col-2 pr-0">
                        <v-img :src="require('../assets/Life-Insurance-Guide_Products_Annuities-Icon.svg')"
                               width="30"
                        ></v-img>
                    </v-col>
                    <v-col class="col-10 pl-0 ml-md-n2 benefit-item-text" >
                        Savings on eligible healthy foods at the grocery store.<sup style="font-size:0.6rem;">3</sup>
                    </v-col>
                </v-row>
                <v-row class="pa-4 mt-n8">
                    <v-col class="col-2 pr-0">
                        <v-img :src="require('../assets/Group 5903.svg')"
                               width="30"
                        ></v-img>
                    </v-col>
                    <v-col class="col-10 pl-0 ml-md-n2 benefit-item-text" >
                        Earn exclusive travel discounts from Hotels.com.
                    </v-col>
                </v-row>
                <div style="font-size: 1rem;font-family: Montserrat, Bold; font-weight: bold; color: black" class="mt-4 mb-5">
                    Free FitBit or an Apple Watch for $25
                </div>
                <v-row class="pa-4 mt-n8">
                    <v-col class="col-2 pr-0">
                        <v-img :src="require('../assets/ghmghm.png')"
                               width="30"
                        ></v-img>
                    </v-col>
                    <v-col class="col-10 pl-0 ml-md-n2 benefit-item-text" >
                        The latest Apple Watch<sup>®</sup> for as little as $25 plus tax, earned with regular exercise.<sup style="font-size:0.6rem;">1</sup>
                    </v-col>
                </v-row>
                <v-row class="pa-4 mt-n8">
                    <v-col class="col-2 pr-0">
                        <v-img :src="require('../assets/amazon-halo-fitness-tracker-band-coming-soon-thoughts-apple-watch-fitbit.png')"
                               width="30"
                        ></v-img>
                    </v-col>
                    <v-col class="col-10 pl-0 ml-md-n2 benefit-item-text" >
                        Complimentary Amazon Halo and a free, three-year Halo membership.<sup style="font-size:0.6rem;">**4</sup>
                    </v-col>
                </v-row>
                <v-row class="pa-4 " style="color:#A2A2A2;font-family: Montserrat">
                    **Clients can also get a complimentary Amazon Halo with a free, three-year Halo membership or Fitbit device instead of Apple Watch when they become a member. Or if they prefer a different wearable device, they can earn a 25%-40% discount on Polar, Garmin or another style of Fitbit. Discount based on device type.
                    <br><br>
                    <a style="color:#46C3B2;" class="mr-1" href="https://quility.com/vitality-benefits-disclosure/" target="_blank">Click here </a>for more important John Hancock disclosures.
                </v-row>
                <v-row class="pa-4" style="color:#A2A2A2;font-family: Montserrat">
                    MLI083021349-1
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "Jh_benefit_dialog",
    props:['visible'],
    computed: {
        show: {
            get() {
                return this.$attrs.value
            },
            set(value) {
                if(!value) {
                    this.$emit('input', value);
                }
            }
        }
    }
}
</script>

<style scoped>
.benefit-item-text {
    font-size: 1rem; font-family: Montserrat, Light; font-weight: 300; color: black;
}

@media only screen and (max-width: 768px) {
    .v-application .pl-0 {
        padding-left: 5% !important;
    }

    .benefit-item-text {
        padding-left: 5% !important;
        word-break: keep-all;
    }

}
</style>
