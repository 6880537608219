<template>
    <v-app>
        <NavBar v-if="quility_site" :name="$route.name"></NavBar>
        <NavBarSymmetry v-if="symmetry_site"></NavBarSymmetry>
        <v-main class="main-background">
            <v-container>
                <v-row align="center" justify="center">
                    <v-col class="col-xl-8 ">
                        <div class="main-container-div">
                            <router-view/>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
        <Footer v-if="quility_site"></Footer>
        <OC_Footer v-if="symmetry_site"></OC_Footer>
    </v-app>
</template>

<script>
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import NavBarSymmetry from "./components/NavBarSymmetry";
import OC_Footer from "./components/OC_Footer";

export default {
    name: 'App',
    data: () => ({
        site_owner: null,
        quility_site: true,
        symmetry_site: false
    }),
    components: {
        OC_Footer,
        NavBarSymmetry,
        NavBar,
        Footer
    },
    created() {
        this.checkIP()

        const route_path = this.$route.path.split('/');
        this.updateNavFooter(route_path[1]);
    },
    watch: {
        $route (to){
            const route_path = to.path.split('/');
            this.updateNavFooter(route_path[1]);


        }
    },
    methods: {
        updateNavFooter(routeName) {
            if(routeName == '') {return false}

            this.site_owner = (routeName === 'symmetry') ? 'Symm' : 'quility';
            this.quility_site = (this.site_owner === 'quility');
            this.symmetry_site = (this.site_owner === 'Symm');
        },

        async checkIP()
        {
            const ste_url = process.env.VUE_APP_PROXY + 'verify-ip-address';
            try {
                let res = await fetch(ste_url,{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                })
                let json_data = await res.json()
                // return json_data.data
                // console.log(json_data)

                // alert('check the console');

                if(json_data.refuse)
                {
                    window.location.href = process.env.VUE_APP_REFUSE_REDIRECT
                }
            } catch (error) {
                console.log(error)
                return null
            }
        },
    }
};
</script>

<style>
#app {
    font-family: montserrat freight-display-pro Sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    color: #2c3e50;
    margin-top: 60px;
}

.main-background {
    background: #F7FCFC;
}

.main-container-div {
    margin-top: 10%;background:#F7FCFC
}

body #app .v-application {
    font-family: Montserrat freight-display-pro Sans-serif !important;
}

.v-list-item__content{
    font-size: 1.2rem;
    font-family: montserrat, Sans-serif !important;
}

.v-menu__content {
    margin-top: 1.2%;
}

.dialog-header-text {
    font-family: FreightDispProBlack-Regular, Black !important;
    color:black;
    font-weight: 900 !important;
    font-size: 1.7rem !important;
}

.dialog-context-title {
    font-family: Montserrat !important;
    font-weight: bold !important;
    font-size: 0.8rem;
    color:black;
}

.v-btn {
    text-transform: none !important;
}

.grecaptcha-badge { opacity:0;}



@media only screen and (max-width: 768px) {
    label.v-label.theme--light {
        font-size: 0.8rem;
    }
}
</style>

