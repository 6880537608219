<template>
    <div class="row pb-5">
        <div class="col-md-7 col-xs-12 pr-4">
            <v-card flat style="background: #F7FCFC;">
                <v-card-title>
                    <h4 class="get-a-quote-title ">{{product_type}}</h4>
                </v-card-title>
                <v-card-text>
                    <p class="estimate-msg">
                        Let’s start with your<br>free, instant quote.
                    </p>
                    <div class="breaking-border hidden-sm-and-down"></div>
                    <p class="reg-thin-text hidden-sm-and-down">
                        With Quility, you have the choice between an online application or a consultation with one of our insurance agents.
                    </p>
                    <v-row class="d-flex justify-space-around my-6 mr-4">
                        <div class="hidden-sm-and-down" style="max-width:185px;">
                            <img src="../assets/over-38-billion-desktop.svg" alt="Over 38 billion in coverage secured for Quility clients" style="width: 100%;" />
                        </div>
                        <div class="hidden-sm-and-down" style="max-width:185px;">
                            <img src="../assets/helping-over-4500-desktop.svg" alt="Helping over 4,500 famlies" style="width: 100%;" />
                        </div>
                    </v-row>
                    <p class="reg-thin-text hidden-sm-and-down">
                        We work with the top insurance companies to deliver products that are best suited for you.
                    </p>
                </v-card-text>
                <v-row class="d-flex align-center justify-space-around hidden-sm-and-down">
                    <div class="hidden-sm-and-down pa-5" style="width:20%">
                        <img src="../assets/moo-logo-stacked-img-desktop.svg" alt="MOO Logo" style="width: 100%;" />
                    </div>
                    <div class="hidden-sm-and-down pa-5" style="width:20%">
                        <img src="../assets/SBLI_BW-img-desktop.svg" alt="SBLI Logo" style="width: 100%;" />
                    </div>
                    <div class="hidden-sm-and-down pa-5" style="width:20%">
                        <img src="../assets/John-Hancock_Black-img-desktop.svg" alt="John Hancock Logo" style="width: 100%;" />
                    </div>
                    <div class="hidden-sm-and-down pa-5" style="width:20%">
                        <img src="../assets/americo-bw-logo-img-desktop.svg" alt="Americo Logo" style="width: 100%;" />
                    </div>
                    <div class="hidden-sm-and-down pa-5" style="width:20%">
                        <img src="../assets/foresters-financial-img-desktop.svg" alt="Foresters Logo" style="width: 100%;" />
                    </div>
                </v-row>
            </v-card>
        </div>
        <div class="col-md-5 col-xs-12 pa-md-8">
            <RAQForm :comp-data="raqData"></RAQForm>
              <v-row class="d-flex justify-space-around pt-12 pb-3">

              <div class="hidden-sm-and-up" style="max-width:185px;">
                            <img src="../assets/over-38-billion-mobile.svg" alt="Over 38 billion in coverage secured for Quility clients" style="width: 100%;" />
                        </div>
                        <div class="hidden-sm-and-up" style="max-width:185px;">
                            <img src="../assets/helping-over-4500-mobile.svg" alt="Helping over 4,500 famlies" style="width: 100%;" />
                        </div>
                    </v-row>
                <v-row class="d-flex align-center justify-space-around ">
                <div class="hidden-sm-and-up pt-6 pb-6 px-5" style="min-width:100%">
                    <p class="reg-thin-text hidden-sm-and-up">
                        We work with the top insurance companies to deliver products that are best suited for you.
                    </p>
                </div>
                <div class="hidden-sm-and-up pa-5" style="width:45%">
                    <img src="../assets/moo-logo-stacked-img-desktop.svg" alt="MoO Logo" style="width: 100%;" />
                </div>
                <div class="hidden-sm-and-up pa-5" style="width:45%">
                    <img src="../assets/SBLI_BW-img-desktop.svg" alt="SBLI Logo" style="width: 100%;" />
                </div>
                <div class="hidden-sm-and-up pa-5" style="width:45%">
                    <img src="../assets/John-Hancock_Black-img-desktop.svg" alt="John Hancock Logo" style="width: 100%;" />
                </div>
                <div class="hidden-sm-and-up pa-5" style="width:45%">
                    <img src="../assets/americo-bw-logo-img-desktop.svg" alt="Americo Logo" style="width: 100%;" />
                </div>
                <div class="hidden-sm-and-up pa-5" style="width:45%">
                    <img src="../assets/foresters-financial-img-desktop.svg" alt="Foresters Logo" style="width: 100%;" />
                </div>
            </v-row>
        </div>
    </div>
</template>
<script>
import quilityMixin from "@/mixins/quilityMixin";

import RAQForm from "./RAQForm";


export default {
    name: "GetAQuote",
    components: {
        RAQForm,
    },
    mixins: [quilityMixin],
    data: () => ({
        valid: false,
        agentData: {},
        raqData: {},
        product_type: 'Term Life'
    }),
    methods: {
        openZChat() {
            this.openZohoChat();
        }

    },
    mounted() {
        const productType = this.$route.query.p;
        this.product_type = (typeof productType != 'undefined' && productType.toUpperCase() === 'MP') ? 'Mortgage Protection' : 'Term Life';
        this.raqData.site = 'QUILITY';
        this.raqData.agentInfo = this.getAgentInfo('QUILITY');
    }
}
</script>
<style scoped>
.get-a-quote-title {
    font-family: "Montserrat", Sans-serif !important;
    font-weight: 300;
    text-align: left;
    line-height: 1em;
    color: #002421;
}

.estimate-msg {
    font-size: 3rem;
    color: #46C3B2;
    font-family: freight-display-pro, Sans-serif !important;
    line-height: 1.3em;
    font-weight: 900;
}

.reg-thin-text {
    margin-top: 3%;
    font-size: calc(16px + (20 - 16) * ((100vw - 400px) / (2000 - 400))) !important;
    font-family: "Montserrat", Sans-serif !important;
    font-weight: 300;
    line-height: 1.5rem;
    color: #000000;
}

.med-thin-text {
    margin-top: 3%;
    font-size: calc(18px + (35 - 22) * ((100vw - 400px) / (2000 - 400))) !important;
    font-family: "Montserrat", Sans-serif !important;
    font-weight: 300;
    line-height: 2.5rem;
    color: #000000;
}

.breaking-border {
    background: #DAF3F0;
    width: 51.58px;
    height: 8.38px;
    margin-top: 2%;
}


.price-teaser-period {
    font-size: 0.8rem;
}

.dialog-text {
    font-family: Montserrat;
    font-weight: normal;
    font-size: 0.8rem;
    color: black;
}

.theme--light.v-btn.v-btn--has-b {
    background-color: #005951;
    color: white;
}

@media only screen and (max-width: 768px) {
    .estimate-msg {
        font-family: freight-display-pro, Sans-serif !important;
        font-size: 2rem;
        line-height: 1.2em;
        margin-bottom: 0;

    }

    .get-a-quote-block {
        font-size: 0.8rem;
        white-space: pre-wrap;
        word-break: keep-all;
    }

    .price-teaser {
        margin-top: 14%;
    }

    .price-teaser-period {
        margin-top: 6%;
        font-size: 0.8rem;
        white-space: nowrap;
        word-break: keep-all;
    }

    input::-webkit-input-placeholder {
        color: red;
    }

    input:-moz-placeholder {
        color: red;
    }
}
</style>