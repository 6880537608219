<template>
    <v-footer style="background: #ffffff;">
        <v-container >
            <v-row>
                <v-col cols="12">
                <v-card flat style="background: #ffffff">
                    <v-card-text>
                        <div style="font-family: Montserrat; opacity: 0.66; font-weight: 300;">
                            <p class="mt-4" style="padding:2rem 0 0 0; white-space: pre-wrap;word-break: keep-all;">
                                *The rates shown above are not final. All final life insurance approvals and actual quotes are subject to personal health history and carrier-specific underwriting guidelines. Your actual rates and
                                quotes may vary, and the underwriting process may determine that your actual premium quotes are higher or lower than the rates shown above. You will have a chance
                                to review and accept the terms and conditions of any insurance policy you may apply for before you are charged. Not all applicants will qualify.
                            </p>
                            <p class="mt-4">
                                By providing your information and/or utilizing the Service
                                (as defined in the <a target="_blank" style="color: #46C3B2" href="https://quility.com/terms-and-conditions/">Terms & Conditions</a> and <a target="_blank" style="color: #46C3B2" href="https://quility.com/privacy-policy" >Privacy Policy</a>) and
                                this Website (as defined in the <a target="_blank" style="color: #46C3B2" href="https://quility.com/terms-and-conditions/">Terms & Conditions</a> and <a target="_blank" style="color: #46C3B2" href="https://quility.com/privacy-policy" >Privacy Policy</a>),
                                you agree to this Website’s <a target="_blank" style="color: #46C3B2" href="https://quility.com/privacy-policy" >Privacy Policy</a> and <a target="_blank" style="color: #46C3B2" href="https://quility.com/terms-and-conditions/">Terms & Conditions</a> and you
                                consent to be contacted (by emails, telephone calls, text messages, SMS or other
                                forms of electronic communication such as a mobile application’s push notifications to the
                                contact information you provide) by Quility Insurance Holdings LLC, any one or more of its
                                direct or indirect subsidiaries, sister companies, or parent companies, or any of their employees or
                                independent contractors or other affiliates ("Quility"), regarding the Service (as defined in the <a target="_blank" style="color: #46C3B2" href="https://quility.com/terms-and-conditions/">Terms & Conditions</a> and <a target="_blank" style="color: #46C3B2" href="https://quility.com/privacy-policy" >Privacy Policy</a>)
                                generally as well as regarding news, special offers and general information about other goods, services and events
                                which we offer that are similar to those that you have already purchased or inquired about.
                            </p>

                            <p class="mt-4">
                                John Hancock insurance products issued by: John Hancock Life Insurance Company (U.S.A.), Boston, MA 02116.
                            </p>
                            <p class="mt-4">
                                John Hancock Policy Form Series: ICC21 21LFTERMHE, 21LFTERMHE.
                                <a style="color: #46C3B2" href="https://quility.com/vitality-benefits-disclosure/" target="_blank">Click here</a> for more important John Hancock disclosure.
                            </p>

                            <p class="mt-4">
                                "Quility Level Term" is a brand name used by Quility, for level term life insurance policies issued by The
                                Savings Bank Mutual Life Insurance Company of Massachusetts ("SBLI") on policy form series 21-P-SIT.
                                Centrian Life Insurance is a registred trade name and Connecticut d/b/a of SBLI.
                                Policies sold in the state of Connecticut will be issued under Centrian Life Insurance ("Centrian").
                            </p>

                            <p class="mt-4">
                                MLI083021349-1
                            </p>
                            <p class="mt-5">
                                Copyright © 2020 Quility Holdings, LLC. All Rights Reserved. | <a target="_blank" style="color: #46C3B2" href="https://quility.com/privacy-policy" >Privacy Policy</a>
                            </p>
                        </div>
                    </v-card-text>
                </v-card>
                </v-col>
            </v-row>

        </v-container>
    </v-footer>
</template>

<script>

export default {
    name: "Footer",
}
</script>

<style scoped>

</style>
