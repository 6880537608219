<template>
    <v-container class="q-no-qoutes px-4">
        <v-row class="d-flex ">
            <v-col cols="sm-8 md-12" class="pr-7">
                <h3 class="serif t-water-green body-45 boldest mb-4">Thanks for your interest in life insurance coverage through Quility!</h3>
                <p class="bold body-20 ">
                    What’s next?
                </p>
                <p class="mb-12 light body-20">
                    Based on the information you provided, we are assigning a specialist to help tailor the perfect coverage options for you.
                </p>
                <v-row class="d-flex pt-4 px-4" style="background-color: #DAF3F0;">
                    <v-col cols="12" sm="12" md="6" class="px-4">
                        <div class="numbers">1</div> <span class="body-20 bold forest-green numbers-text">Connect with a specialist</span>
                        <p class="body-20 light pt-3">
                            A Quility specialist will call you to schedule an appointment to review your policy options — virtually, over the phone or in person.
                        </p>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="px-4">
                        <div class="numbers">2</div> <span class="body-20 bold forest-green numbers-text">Get coverage!</span>
                        <p class="body-20 light pt-3">You’ll secure the life insurance coverage that best suits your needs.</p>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4" align-self="end" class="pl-7 pb-0 mb-0 d-none d-md-block">
                <img src="../assets/RAQ-not-eligible-img-desktop-only@2x.png" alt="Helping over 4,500 famlies" style="width: 100%; marign:0; padding:0;" class="pa-0 ma-0" />
            </v-col>
            <v-col cols="8">
                <p class="mt-5 ml-0">
                    <a @click="$emit('meetAgentClick')" target="_blank" style="color: #46C3B2; text-decoration:underline; font-weight:500;">Click here</a> if you would rather schedule a consultation with an agent now and pick a time frame that works best for you.
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: "NoQoutes",

    components: {},

    props: [
        'coverage_items',
        'plan_items',
        'assignValuesMethod'
    ],
}
</script>
<style lang="scss" scoped>
.numbers {
    padding: 1px;
    color: #fff;
    background-color: #005851;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    float: left;
    position: absolute;

}

.numbers-text {
    margin-left: 40px;
    display: inline-block;
}

.q-no-qoutes {
    * {
        font-family: Montserrat;
    }

    // h3 {
    //     font: normal normal 900 14px/21px Montserrat;
    // }
    // p {
    //     font: normal normal normal 14px/21px Montserrat;

    //     strong {
    //         font: normal normal 700 13px/21px Montserrat !important;
    //     }
    // }
    .v-btn {
        font: normal normal medium 12px/10px Montserrat;
    }
}
</style>