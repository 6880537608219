
    <template>
        <v-dialog width="580" v-model="show">
            <v-card>
                <v-card-title class="dialog-header-text">
                    Benefits
                    <v-spacer></v-spacer>
                    <v-icon large color="#46C3B2" @click.stop="show=false">
                        mdi-close
                    </v-icon>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-5">

                    <div style="font-size: 1rem;font-family: Montserrat, Bold; font-weight: bold; color: black" class="mt-7 mb-1">
                        Confined care and chronic illness coverage
                    </div>
                    <v-row class="pa-4">
                        <v-col class="col-2 pr-0">
                            <v-img :src="require('../assets/Life-Insurance-Guide_Products_Disability-Icon.svg')"
                                   width="30"
                            ></v-img>
                        </v-col>
                        <v-col class="col-10 pl-0 ml-md-n2 benefit-item-text" >
                            If you become chronically ill and need funds for day-to-day care, you can access your life insurance death benefit to help pay for expenses.
                        </v-col>
                    </v-row>
                    <v-row class="pa-4 mt-n8">
                        <v-col class="col-2 pr-0">
                            <v-img :src="require('../assets/Life-Insurance-Guide_Products_Final-Expense-Icon.svg')"
                                   width="30"
                            ></v-img>
                        </v-col>
                        <v-col class="col-10 pl-0 ml-md-n2 benefit-item-text" >
                            Accelerated benefit riders give a portion of the death benefit to the policy owner for various conditions.
                        </v-col>
                    </v-row>
                    <div style="font-size: 1rem;font-family: Montserrat, Bold; font-weight: bold; color: black" class="mt-4 mb-5">
                        Term conversion
                    </div>
                    <v-row class="pa-4 mt-n8">
                        <v-col class="col-2 pr-0">
                            <v-img :src="require('../assets/Life-Insurance-Guide_Products_Perm-Life-Icon.svg')"
                                   width="30"
                            ></v-img>
                        </v-col>
                        <v-col class="col-10 pl-0 ml-md-n2 benefit-item-text" >
                            As the end of your policy’s term approaches, you might find that you still need life insurance to provide a safety net for your beneficiary.
                            In this event, you can convert your term policy to a permanent life insurance policy that does not expire.
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </template>

<script>
export default {
    name: "Am_benefit_dialog",
    props:['visible'],
    computed: {
        show: {
            get() {
                return this.$attrs.value
            },
            set(value) {
                if(!value) {
                    this.$emit('input', value);
                }
            }
        }
    }
}
</script>

<style scoped>
 .benefit-item-text {
     font-size: 1rem; font-family: Montserrat, Light; font-weight: 300; color: black;
 }

 @media only screen and (max-width: 768px) {
     .benefit-item-text {
         padding-left: 5% !important;
         word-break: keep-all;
     }

     .v-application .pl-0 {
         padding-left: 5% !important;
     }
 }
</style>
