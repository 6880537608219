<template>
    <v-row style="font-family: montserrat, Sans-serif !important">
        <div class="col-md-12 align-content-center " >
            <v-card class="pa-2 " flat style="background: #F7FCFC;">
                <v-row>
                    <v-col class="mt-5 pb-5 text-center hidden-md-and-up " style="font: normal normal 900 30px/46px FreightDispProBlack-Regular; color: black;">
                        Apply Online
                    </v-col>
                </v-row>
                <v-row class="mx-8">
                    <v-col class="premium-box text-center px-2 ">
                        <span class="coverage-title">Coverage for as low as</span>
                        <div class="amount"><span class="currency">$</span>{{face_amount}}<span class="cents">.{{face_amt_cents}}</span></div>
                        <div class="amount-period">Monthly<sup>*</sup></div>
                    </v-col>
                    <v-col class="coverage-box text-center pl-0 pt-sm-4 pt-md-8 ">
                        <div class="total-coverage"><sup>$</sup>&nbsp;{{coverage_amount}}<br><p>Coverage</p></div>
                        <div class="line-breaker ml-4" ></div>
                        <div class="total-coverage term-length mt-4">{{term_length}}<br><p>Term</p></div>
                    </v-col>
                </v-row>
            </v-card>

            <div class="text-center mt-4" style="color: #46C3B2;" >
                    <v-dialog
                            v-model="dialog"
                            width="500"
                    >
                        <template v-slot:activator="{ on, attrs }" >
                            <label v-bind="attrs" v-on="on" id="editQuote">Edit Quote</label>
                        </template>

                        <v-card >
                            <v-card-title class="dialog-header-text">
                                Edit Quote
                                <v-spacer></v-spacer>
                                <v-icon large color="#46C3B2" @click="dialog = false" > mdi-close </v-icon>
                            </v-card-title>

                            <v-card-text class="mt-0">
                                <v-divider></v-divider>
                                <v-form>
                                    <v-row class="mt-4">
                                        <v-col cols="12">
                                            <v-select
                                                    label="Coverage Amount"
                                                    outlined
                                                    v-model="m_coverage_amount"
                                                    hide-details="auto"
                                                    dense
                                                    :items="coverage_items"
                                                    color="black"
                                                    class="input-control-text" >
                                                <template v-slot:append-outer >
                                                    <Cov_amt_help_dialog></Cov_amt_help_dialog>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-select
                                                    dense
                                                    label="Term Length"
                                                    outlined v-model="m_term_length"
                                                    hide-details="auto"
                                                    :items="plan_items"
                                                    class="dropdown-style input-control-text "
                                            >
                                                <template v-slot:append-outer>
                                                    <Term_length_help_dialog></Term_length_help_dialog>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-select label="Tobacco used in last 12 months" :items="['Yes', 'No']" dense v-model="m_tobacco_used"
                                                      outlined hide-details="auto" class="input-control-text"
                                                      >
                                                <template v-slot:append-outer>
                                                    <Tobacco_help_dialog></Tobacco_help_dialog>
                                                </template>
                                            </v-select>
                                        </v-col>

                                    </v-row>
                                </v-form>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-btn class="input-control-text btn-text"
                                       type="button" large dark block depressed color="#005851" @click="checkQuote" :disabled="btnDisabled" >Save Changes</v-btn>
                                <v-progress-linear
                                        :active="loading"
                                        :indeterminate="loading"
                                        absolute
                                        bottom
                                        color="black accent-4"
                                ></v-progress-linear>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

            </div>
            <div class="mt-6 text-center hidden-md-and-down" style="font: normal normal 900 46px/54px FreightDispProBlack-Regular; color: black;">
                Apply Online
            </div>
            <div class="text-center mt-5 advisor-option" >
                Ready to apply? You’ll be on your way to securing the coverage you need in 10 minutes.
            </div>
        </div>
        <div class="col-md-8 offset-md-2 align-content-center">
            <div class=" mt-0 mx-15 btn-apply-now">
                <v-btn large dark block depressed color="#005851"  style="text-transform: none;font-size: 1.2rem;" @click="gotoEapp" id="JHApplyNowBtn">Apply Now</v-btn>
            </div>
        </div>
        <v-dialog
                v-model="eApp_dialog"
                persistent
                width="300"
                style="font-family: montserrat, Sans-serif"
        >
            <v-card
                    color="#005851"
                    dark
            >
                <v-card-text style="font-family: montserrat, Sans-serif; font-size: 1rem; line-height: 1rem;"
                             class="pa-3">
                    Starting e-Application
                    <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0 mt-4"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>

</template>

<script>
import numeral from "numeral";
import axios from "axios";
import quilityMixin from "@/mixins/quilityMixin";
import Cov_amt_help_dialog from "@/components/cov_amt_help_dialog";
import Term_length_help_dialog from "@/components/term_length_help_dialog";
import Tobacco_help_dialog from "@/components/tobacco_help_dialog";

let moment = require('moment');

export default {
    name: "TermQuoteVertical",
    components: {Tobacco_help_dialog, Term_length_help_dialog, Cov_amt_help_dialog},
    mixins: [quilityMixin],
    props: {
        params: {}
    },
    data:() =>({
        coverage_amt_dialog: false,
        term_length_dialog:false,
        tobacco_dialog: false,
        eApp_dialog: false,
        loading: false,
        btnDisabled:false,
        coverage_amount:'0',
        term_length:'0',
        m_coverage_amount: '0',
        m_term_length: '0 Years',
        face_amount:'0',
        face_amt_cents:'00',
        premium:'',
        plan_items:  [
            {text: '10 Years', value: '10 Years'},
            {text: '15 Years', value: '15 Years'},
            {text: '20 Years', value: '20 Years'},
            {text: '25 Years', value: '25 Years'},
            {text: '30 Years', value: '30 Years'},
        ],
        coverage_plan:'',
        m_tobacco_used:null,
        dialog:false,
        zoho_id: null
    }),
    mounted() {
        const parameters = this.$route.params;
        this.assignValues(parameters.form_data, parameters.quotes);
        this.zoho_id = parameters.zoho.ZohoID;
    },
    computed: {
        coverage_items: {
            get() {
                return this.setCoverageItems(20);
            },
            set(newCoverageItems) {
                return newCoverageItems
            }
        }
    },
    methods:{
        formatCurrency(a) {
            return numeral(a).format('$ 0,0[.]00');
        },
        assignValues(form_data, quotes) {
            const coverageAmt = form_data.coverage_amount;
            this.coverage_amount = new Intl.NumberFormat().format(coverageAmt);
            this.term_length = form_data.term_length;

            this.m_coverage_amount = coverageAmt;
            this.m_term_length = form_data.term_length;
            this.m_tobacco_used = form_data.smoker;

            quotes.forEach((item) => {
                if(item['Carrier'] === 'John Hancock') {
                    const offered_premium = item['Premium'].toString();
                    this.premium = offered_premium;
                    const offered_premium_breakdown = offered_premium.split('.');
                    this.face_amount = offered_premium_breakdown[0];
                    this.face_amt_cents = offered_premium_breakdown[1];
                }
            })
        },

        checkQuote() {
            this.loading = true;
            this.btnDisabled = true;
            let ste_url = process.env.VUE_APP_PROXY + 'get-quote';
            const parameters = this.$route.params;

            let postParam = {
                gender: parameters.form_data.gender,
                productType:'Term',
                smoker:this.m_tobacco_used,
                zip:parameters.form_data.zip,
                dob:parameters.form_data.dob,
                coverage_amount: this.m_coverage_amount,
                term_length: this.m_term_length,
                firstName: parameters.form_data.firstName,
                lastName: parameters.form_data.lastName,
                email: parameters.form_data.email,
                state_id: parameters.form_data.state_id,
                city: parameters.form_data.city,
                age: parameters.form_data.age,
                entry_point: parameters.form_data.entry_point,
                phone: parameters.form_data.phone,
                mode:'edit'
            }

            axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            axios.post(ste_url, postParam)
                    .then(res => {
                        this.loading = false;
                        this.dialog = false;
                        if(res.data.success === true ) {
                            this.loading = false;
                            this.btnDisabled = false;
                            let quoteParams = {};
                            quoteParams['quotes'] = res.data.quotes;
                            quoteParams['form_data'] = postParam;
                            // this.$router.push({ name: 'TermCoverage', params: quoteParams })
                            this.assignValues(postParam, res.data.quotes);
                        }
                    })
                    .catch(err => {
                        this.loading = false;
                        console.log(err)
                    });
        },

        gotoEapp() {
            this.eApp_dialog = true;
            const parameters = this.$route.params;
            const phone = parameters.form_data.phone;
            let phone_clean = phone.replace('(', '');
            phone_clean = phone_clean.replace(')','');
            const queryParam = {
                firstName: parameters.form_data.firstName,
                lastName: parameters.form_data.lastName,
                dob: moment(parameters.form_data.dob, 'YYYY-MM-DD').format('MM-DD-YYYY'),
                gender: parameters.form_data.gender,
                state: parameters.form_data.state_id,
                phone: phone_clean,
                coverageAmount: numeral(this.m_coverage_amount).format('0,0[.]00'),
                coverageLength: this.m_term_length.substring(0,2),
                smoker: this.m_tobacco_used === 'Yes' ? 'Smoker' : 'NonSmoker',
                healthClass:'Excellent',
                estimateMonthly: this.premium,
                email: parameters.form_data.email,
                applicantId: parameters.zoho.ZohoID,
                salesSource: 'ain',
                salesSourceVariation: 'quility',
                productType: 'lft',
                agentEmail:'Jason.ditson@quility.com',
                agentId:'560197244',
                agentLicense:'a304959',
                agentLicenseState: parameters.form_data.state_id,
                agentName:"Brian Pope",
                agentPhone:"916-723-8292",
                agentAddress:'3010 Lava Ridge Court, Ste 200',
                agentCity: 'Roseville',
                agentState:'CA',
                agentZip:'95661',
                agentPayrollID:'957180',
                customSkin: 'quility',
                zoho_id: this.zoho_id,
                token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJpYXQiOjE1NTQ3NTgzNjN9.N2MzMzBhMDY3YTYzNWQ4ODQ5YWU5MjVhNmU4YTAzMGIwODVjM2RmMDU1MTk2Y2IwYjI0ZWRkNDc2ZTg1OWQzOQ=='
            };

            let query_string = Object.keys(queryParam).map(function(key) {
                return key + '=' + queryParam[key];
            }).join('&');

            const redirect_url = process.env.VUE_APP_JH_EAPP_URL + 'processor/intake?'+query_string

            this.updateDisposition(this.zoho_id, 'JH Initiated', null).then(res => {
                console.log(res);
                window.location.href = redirect_url;
            });



        },
        setCoverageItems(coverage_limit) {
            let r = []
            for (let i = 0; i < coverage_limit; i++) {
                let amt = (25000 + (i * 25000));
                r.push({
                    'text': this.formatCurrency(amt),
                    'value': amt
                })
            }
            r.reverse();
            return r;
        },

        openZChat() {
            this.openZohoChat();
        }
    } //eof methods

}
</script>

<style scoped>
.premium-box {
    background: #47C3B2;color: white; height:12rem;
}
.coverage-box {
    background: white;height:12rem;
}

.amount {
    font-size: 3.5rem;
    font-weight: 900;
    margin-top:2%;
}

.amount span.cents {
    font-size: 0.3em;
    position: relative;
    bottom: 2em;
    padding-left: 2%;
}

.amount span.currency {
    font-size: 1.2rem;
    position: relative;
    bottom: 2em;
    margin-right: 0.6em;
}

.amount-period {
    letter-spacing: 0.09px;
    font-weight: 600;
}

.total-coverage {
    font: normal normal 900 25px/7px Montserrat;
    line-height: 1.7rem;
}

.total-coverage p{
    font-weight: normal;
    letter-spacing: 0.07px;
    font-size: 1rem;

}

.advisor-option {
    font-family: Montserrat !important;
    font-size: 1.3rem;
    font-weight: 300;
    color: black;
}

.line-breaker {
    border-bottom: 0.5px solid #000000;
}

.meet-advisor h3 {
    text-align: center;
    font-family: FreightDispProBlack-Regular;
    font-weight: 900;
    font-size: 2vw;
}
.meet-advisor p{
    font-family: Montserrat-Light;
    font-weight: 300;
    text-align: center;
}

.input-control-text{
    font-family: "Montserrat", Sans-serif !important;
}

.btn-text {
    text-transform: none; font-family: montserrat, Sans-serif
}

.coverage-title {
    font-size: 1.4rem; line-height: 0.3rem;
    white-space: pre-wrap;
    word-break: keep-all;
}

@media only screen and (max-width: 768px) {
    .coverage-title {
        font-size: 1rem;
    }

    .amount {
        font-size: 2.5rem;
        margin-top:1%;
    }

    .amount span.cents {
        bottom: 1.3em;
    }

    .amount span.currency {
        bottom: 1em;
    }

    .amount-period {
        font-family: Montserrat, Medium;
        font-weight: Medium;
    }

    .total-coverage {
        font: normal normal 600 20px/7px Montserrat;
        line-height: 1.7rem;
    }

    .premium-box {
        height:10rem;
    }
    .coverage-box {
        height:10rem;
    }
}

@media only screen and (min-width: 1904px) {
    .v-application .mx-15 {
        margin-left: 30% !important;
        margin-right: 30% !important;
    }
}
</style>
