<template class="q-main-navbar">
    <v-app-bar fixed
               color="white"
               height="100">
        <v-container class="container">
            <v-row no-gutters>
                <v-col  class="col-md-3 ">
                    <a href="https://quility.com/">
                        <v-img
                                alt="Quility Logo"
                                :src="require('../assets/quility-logo-r.svg')"
                                max-height="40"
                                contain
                        />
                    </a>
                </v-col>
                <v-col class="text-md-center " v-if="progress_nav == true">
                    <div class="menu-main-nav-container mt-2" v-if="hasSteps && !$vuetify.breakpoint.xs" >
                        <div class="mb-2">
                            <div :class="step === 'yourInfo' ? 'steps active' : 'steps'" style="text-align: left">
                                Your Info
                            </div>
                            <div :class="step === 'yourQuote' ? 'steps active' : 'steps'">
                                Your Quote
                            </div>
                            <div :class="step === 'yourApplication' ? 'steps active' : 'steps'" style="text-align: right">
                                Your Application
                            </div>
                        </div>
                        <div class="progress-steps mx-10">
                            <v-progress-linear color="#46C3B2" rounded :value="stepperValue" height="8"></v-progress-linear>
                        </div>
                    </div>
                </v-col>
                <v-col class="text-md-center hidden-md-and-down" v-if="progress_nav === false" style="color:black;font-family: montserrat, Sans-serif">
                    <v-row class="mt-md-4" no-gutters >
                        <v-col class="contactUsLink">
                            What We Offer
                        </v-col >
                        <v-col class="contactUsLink">
                            The Basics
                        </v-col>
                        <v-col class="contactUsLink">
                            About Us
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class=" col-md-3 ">
                    <div class="text-md-right mt-md-4">
                        <v-menu open-on-hover >
                            <template v-slot:activator="{ on, attrs }"  >
                                <span v-bind="attrs" v-on="on" id="contactus" class="contactUsLink hidden-md-and-down ">Contact us</span>
                                <v-app-bar-nav-icon v-bind="attrs" v-on="on" class="hidden-md-and-up"></v-app-bar-nav-icon>
                            </template>
                            <v-list class="pa-5" style="font-family: montserrat, Sans-serif;">
                                <v-list-item >
                                    <div>
                                        <h3><v-icon large color="teal darken-2" > mdi-email </v-icon>
                                            Need Help?
                                        </h3>
                                        <p >Get in touch with us today.</p>
                                    </div>
                                </v-list-item>
                                <br/>
                                <v-list-item>
                                    <div>
                                        <h3 >
                                            <v-icon large color="teal darken-2" > mdi-message-text </v-icon>
                                            <a href="#" class="cta-open-chat" id="chatRef" @click="openChat"
                                               style="text-decoration: none;color:#212121">Live Chat</a>
                                        </h3>
                                        <p >We’re happy to answer your questions.</p>
                                    </div>
                                </v-list-item>
                                <br/>
                                <v-list-item>
                                    <div>
                                        <h3>
                                            <v-icon large color="teal darken-2" > mdi-phone-in-talk </v-icon>
                                            <a href="tel:8007845489"  style="color:#212121; text-decoration: none;" id="telNo">1-888-784-5489</a>
                                            <div class="ml-10 mt-n3" style="color: #46C3B2; font-size: 1rem; font-weight: normal;">(QUILITY)</div>
                                        </h3>
                                        <p >Monday - Friday<br>9am - 9pm ET</p>
                                    </div>
                                </v-list-item>

                            </v-list>
                        </v-menu>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-app-bar>
</template>

<script>
import quilityMixin from "@/mixins/quilityMixin";

export default {
    name: "NavBar",
    props: [name],
    mixins: [quilityMixin],
    data() {
        return {
            hasSteps: true,
            step: 'yourInfo',
            stepperValue:30,
            progress_nav: true,
            chatClick: false
        }
    },
    watch:{
        $route (){
            this.setNavbarItems(this.$route.path);
        }
    },
    mounted() {
        this.setNavbarItems(this.$route.path);
    },
    methods:{
        setNavbarItems(routePath) {
            if(routePath === '/') {
                this.step = 'yourInfo';
                this.stepperValue = 30;
            }
            else if(routePath === '/termQuote') {
                this.step = 'yourQuote';
                this.stepperValue = 60;
            }
            else if(routePath === '/quotes') {
                this.step = 'yourQuote';
                this.stepperValue = 60;
            }
            else if(routePath === '/thank-you') {
                this.progress_nav = false;
            }
        },
        openChat() {
            this.openZohoChat();
        }
    }
}
</script>

<style scoped>
    .menu-main-nav-container .steps {
        font-family: montserrat, Sans-serif;
        display: inline-block;
        font-size: 0.9rem;
        width: 33%;
        text-align: center;
        color: #aaaaaa;
        font-weight: normal;
    }

    .menu-main-nav-container .steps.active {
        color: #002421;
        font-weight: 500;
    }

    .q-main-navbar {
        font-family: montserrat, Sans-serif !important;
    }

    .contactUsLink {
        font-family: montserrat, Sans-serif;font-weight: normal;
        text-align: center;
        font-weight: 500;
    }
    .contactUsLink:hover{
        border-bottom: 3px solid #46C3B2;
        font-weight: 500;
        /*line-height: 2rem;*/
        cursor:pointer;
    }
</style>
