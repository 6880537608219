<template>
    <v-app-bar fixed
               color="white"
               height="100">
        <v-container class="container">
            <v-row no-gutters>
                <v-col  class="col-md-3 ">
                    <a :href="redirectUrl" v-if="redirectExists">
                        <img src="../assets/SYMM_PbQ_blk-Horizontal-Logo.svg" alt="Symmetry Logo" />
                    </a>
                    <img src="../assets/SYMM_PbQ_blk-Horizontal-Logo.svg" alt="Symmetry Logo" v-if="!redirectExists" />
                </v-col>
            </v-row>
        </v-container>
    </v-app-bar>
</template>

<script>
export default {
    name: "NavBarSymmetry",
    data:() => ({
        redirectUrl:null,
        redirectExists: false,
    }),
    mounted() {
        if(typeof this.$route.query.redirect_url!='undefined') {
            this.redirectExists = true;
            this.redirectUrl = this.$route.query.redirect_url
        }
    }
}
</script>

<style scoped>

</style>